import { useEffect, useState } from 'react';
import Camera, { FACING_MODES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
Camera.defaultProps = undefined
export default function CameraModal({ className, title, cancel, photo, onHandleTakePhoto, onHandleConfirmPhoto, confirmTitle, onHandleCancelPhoto }) {
    const [errorCamera, setErrorCamera] = useState(false);
    const [cameraPermission, setCameraPermission] = useState(null); // null, 'granted', 'denied', or 'prompt'

    useEffect(() => {
        // Verificar el permiso de la cámara
        if (navigator.permissions) {
        navigator.permissions.query({ name: 'camera' })
            .then((permissionStatus) => {
            setCameraPermission(permissionStatus.state);
            
            // Escuchar cambios en el estado del permiso
            permissionStatus.onchange = () => {
                setCameraPermission(permissionStatus.state);
            };
            })
            .catch((error) => {
            console.error('No se pudo consultar el estado de los permisos:', error);
            });
        } else {
        console.warn('La API de permisos no es compatible con este navegador.');
        }
    }, []);
    console.log('cameraPermission',cameraPermission)

    return (
        <div className={className + " fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full !mt-0"}>
            <div className='relative inset-y-1/4 top-16 lg:top-2 mx-auto my-auto p-5 border w-full border-gray-200 rounded-lg shadow-md bg-white'>
                <div className="flex justify-end py-3 border-b-2">
                    <div className='w-full mx-auto border-r'>
                        <h1 className='text-left font-bold text-xl'>
                            {title}
                        </h1>
                    </div>
                    <div className='mx-4'>
                        <button onClick={cancel}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                </div>
                <div className="w-full py-5">
                    <h2 className='text-center text-lead font-light'>Verifica que la foto pueda verse clara y completamente</h2>
                    {
                        (errorCamera ||cameraPermission != 'granted') && <h2 className='text-center text-lead font-bold'>Activa la camara en el navegador</h2>
                    }
                    <div className="mx-auto">
                        {
                            !photo ?
                                <Camera onTakePhoto={(dataUri) => { onHandleTakePhoto(dataUri); }} idealFacingMode={FACING_MODES.ENVIRONMENT} onCameraError={()=>setErrorCamera(true)} />
                                :
                                !errorCamera &&
                                <div className='flex flex-col space-y-5'>
                                    
                                    <img src={photo} alt="" className='mx-auto' />
                                    <div className="flex justify-center space-x-5">
                                        <button className='border border-pink-500 text-pink-500 rounded-md py-2 px-4 hover:bg-pink-500 hover:text-white transition-colors duration-300 ' onClick={onHandleConfirmPhoto}>{confirmTitle ? confirmTitle : 'Confirmar'}</button>
                                        <button className='inline-block  w-50 items-center px-4 py-2 border border-gray-700 rounded-md text-lead hover:bg-gray-200 focus:bg-gray-200 active:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition ease-in-out duration-150 disabled:opacity-25 ' onClick={onHandleCancelPhoto}>Volver a tomar foto</button>
                                    </div>
                                </div>
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}